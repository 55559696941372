import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        fontSize: "1rem",
        lineHeight: "1.5",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        minHeight: "200px",
        position: "relative",
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flex: "1 1 auto",
        color: theme.palette.text.primary,
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        maxWidth: "100%",
        minHeight: "100px",
        position: "relative",
    },
    alert: {
        fontSize: "1rem",
        lineHeight: "1.4",
        padding: "0px 16px",
    },
}));
const RouteFallback = (props) => {
    const classes = useStyles();
    // eslint-disable-next-line no-console
    console.log("RouteFallback: ", props.error);
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.wrapper },
            React.createElement(Alert, { variant: "filled", severity: "error", classes: { root: classes.alert } }, "An error occurred"))));
};
export default RouteFallback;
