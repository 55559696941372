import React, { createContext, useEffect, useCallback, useContext, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 600,
    },
}));
const ToastContext = createContext(null);
export const ToastProvider = ({ children }) => {
    const classes = useStyles();
    const [toasts, setToasts] = useState([]);
    const [open, setOpen] = useState(false);
    const addToast = useCallback((item) => {
        setToasts((toasts) => [...toasts, item]);
    }, []);
    const removeFirstToast = useCallback(() => {
        setToasts((toasts) => toasts.slice(1));
    }, []);
    useEffect(() => {
        if (toasts.length) {
            setOpen(true);
        }
    }, [toasts]);
    const value = {
        toasts,
        showToast: addToast,
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway")
            return;
        setOpen(false);
    };
    const handleExited = () => {
        removeFirstToast();
    };
    const toast = toasts[0];
    let toastMessage = null;
    let toastView = null;
    if (toast) {
        if (typeof toast === "string") {
            toastMessage = toast;
        }
        else {
            toastView = (React.createElement(React.Fragment, null,
                React.createElement("div", null, toast.title || toast.message || ""),
                toast.title && toast.message && React.createElement("div", null, toast.message)));
            if (toast.severity) {
                toastView = (React.createElement(Alert, { onClose: handleClose, severity: toast.severity, elevation: 6, variant: "filled" }, toastView));
            }
        }
    }
    return (React.createElement(ToastContext.Provider, { value: value },
        children,
        toast && (React.createElement(Snackbar, { open: open, autoHideDuration: 5000, message: toastMessage, onClose: handleClose, TransitionProps: { onExited: handleExited }, anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            }, classes: {
                root: classes.root,
            } }, toastView))));
};
export const useToast = () => {
    return useContext(ToastContext);
};
