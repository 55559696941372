import { apiConfig } from "../config/apiConfig";
import apiClient from "../api/ApiClient";
import { Roles } from "../types/user";
const API_URL = apiConfig.apiUrl;
class AuthService {
    login(email, password) {
        return apiClient
            .post(`${API_URL}/auth/login`, {
            email,
            password,
        })
            .then((response) => {
            if (!response.data.data.user.roles.includes(Roles.admin)) {
                throw new Error("wrong permissions for the user");
            }
            if (response.data.data.accessToken) {
                localStorage.setItem("token", JSON.stringify(response.data.data.accessToken));
            }
            return response.data.data.user;
        });
    }
    logout() {
        localStorage.removeItem("token");
    }
    register(parameters) {
        return apiClient
            .post(`${API_URL}/auth/register`, Object.assign({}, parameters))
            .then((response) => {
            if (!response.data.data.user.roles.includes(Roles.admin)) {
                throw new Error("wrong permissions for the user");
            }
            if (response.data.data.accessToken) {
                localStorage.setItem("token", JSON.stringify(response.data.data.accessToken));
            }
            return response.data.data.user;
        });
    }
    checkCode(code) {
        return apiClient
            .post(`${API_URL}/auth/check-code`, {
            code,
        })
            .then((response) => {
            return response.data.data;
        });
    }
    sendEmailForReset(email) {
        return apiClient
            .post(`${API_URL}/auth/reset-password/send-email`, {
            email,
        })
            .then((response) => {
            return response.data.data;
        });
    }
    checkResetId(resetToken) {
        return apiClient
            .post(`${API_URL}/auth/reset-password/check-reset-id`, {
            resetToken,
        })
            .then((response) => {
            return response.data.data;
        });
    }
    updatePassword(data) {
        return apiClient
            .put(`${API_URL}/auth/reset-password/update`, Object.assign({}, data))
            .then((response) => {
            return response.data.data;
        });
    }
    getToken() {
        return JSON.parse(localStorage.getItem("token"));
    }
}
export default new AuthService();
