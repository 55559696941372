var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
const PrivateRoutes = (_a) => {
    var mainProps = __rest(_a, []);
    const { allowedRoutes } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (allowedRoutes.length > 0) {
            mainProps.history.push(allowedRoutes[0].url);
        }
        else {
            mainProps.history.push("/");
        }
        setLoading(false);
    }, [allowedRoutes, mainProps.history]);
    if (loading)
        return null;
    return (React.createElement(Switch, null, allowedRoutes.map((route) => {
        const { url, component: Component, title } = route, rest = __rest(route, ["url", "component", "title"]);
        return (React.createElement(Route, Object.assign({}, rest, { exact: true, key: url, path: url }),
            React.createElement(Component, Object.assign({}, rest, mainProps))));
    })));
};
export default PrivateRoutes;
