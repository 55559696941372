const Routes = {
    // GENERAL
    MAIN: "/",
    SIGN_IN: "/sign-in",
    RESET_PASSWORD: "/reset-password",
    RESET_CHANGE_PASSWORD: "/reset-password/:resetId",
    SETTINGS: "/settings",
    ABOUT: "/about",
    // ADMIN
    CUSTOMERS: "/customers",
    CUSTOMER: "/customers/:customerId",
    SEMINARS: "/seminars",
    SEMINAR: "/seminars/:seminarId",
    SEMINAR_VIDEO: "/seminars/:seminarId/video/:videoId",
    COACHES: "/coaches",
    COACH: "/coaches/:coachId",
};
export default Routes;
