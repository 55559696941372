var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/member-delimiter-style */
import React, { useContext } from "react";
import { Route, Router, Redirect, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { ErrorBoundary } from "react-error-boundary";
import CircularProgress from "@material-ui/core/CircularProgress";
import Routes from "./constants/routes";
import { AuthContext } from "./context/AuthContext";
import Layout from "./components/common/Layout";
import About from "./components/common/About";
import PrivateRoutes from "./routes/PrivateRoutes";
import NotFound from "./components/common/NotFound";
import RouteFallback from "./components/common/RouteFallback";
const Login = React.lazy(() => import(/* webpackChunkName: "Login" */ "./components/auth/SignIn"));
const ResetSendEmail = React.lazy(() => import(/* webpackChunkName: "ResetSendEmail" */ "./components/auth/ResetSendEmail"));
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "ResetPassword" */ "./components/auth/ResetPassword"));
const history = createBrowserHistory();
export const PrivateRoute = (_a) => {
    var { component: Component } = _a, rest = __rest(_a, ["component"]);
    const { authUser } = useContext(AuthContext);
    return (React.createElement(Route, Object.assign({}, rest, { render: (props) => authUser ? (React.createElement(Component, Object.assign({}, props))) : (React.createElement(Redirect, { to: {
                pathname: Routes.SIGN_IN,
                state: { from: props.location },
            } })) })));
};
const RenderRoutes = () => {
    return (React.createElement(Router, { history: history },
        React.createElement(Layout, null,
            React.createElement(ErrorBoundary, { FallbackComponent: RouteFallback },
                React.createElement(React.Suspense, { fallback: React.createElement("div", { style: {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "80%",
                        } },
                        React.createElement(CircularProgress, null)) },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: Routes.SIGN_IN, component: Login }),
                        React.createElement(Route, { path: Routes.ABOUT, component: About }),
                        React.createElement(Route, { exact: true, path: Routes.RESET_PASSWORD, component: ResetSendEmail }),
                        React.createElement(Route, { exact: true, path: Routes.RESET_CHANGE_PASSWORD, component: ResetPassword }),
                        React.createElement(PrivateRoute, { path: Routes.MAIN, component: PrivateRoutes }),
                        React.createElement(Redirect, { to: "/" }),
                        React.createElement(Route, { component: NotFound })))))));
};
export default RenderRoutes;
