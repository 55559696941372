import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
// import Footer from "./Footer"
const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    container: {
        display: "flex",
        flex: 1,
        maxWidth: "1024px",
        margin: "0 auto",
        width: "100%",
        padding: "0 16px",
        [theme.breakpoints.up("lg")]: {
            padding: 0,
        },
    },
    main: {
        maxWidth: "100%",
        flex: 1,
    },
}));
const Layout = ({ children }) => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.root },
        React.createElement(Header, null),
        React.createElement("div", { className: classes.container },
            React.createElement("main", { className: classes.main }, children))));
};
export default Layout;
