/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
const theme = createTheme({
    typography: {
        fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
        button: {
            textTransform: "inherit",
        },
    },
    palette: {
        primary: {
            main: "#52BFD3",
        },
        secondary: {
            main: "#FF505F",
        },
        text: { primary: "#343434" },
        background: {
            paper: "#FFF",
            default: "#FFF",
        },
    },
    overrides: {
        MuiDialog: {
            container: {},
            paper: {
                boxShadow: "none",
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "400px",
            },
        },
        MuiButton: {
            root: {
                borderRadius: "8px",
                fontSize: "1rem",
                letterSpacing: "0.078125rem",
                padding: "6px 14px",
            },
        },
        MuiTextField: {
            root: {
                borderRadius: "8px",
            },
        },
        MuiInputBase: {
            root: {
                borderRadius: "8px",
            },
            input: {
                color: "#343434",
                fontSize: "1rem",
                fontWeight: 400,
                letterSpacing: "0.015625rem",
                lineHeight: 1.5,
                height: "1.1875rem",
                "&.Mui-disabled": {
                    backgroundColor: "#F4F4F4",
                    color: "rgba(0, 0, 0, 0.38)",
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "8px",
            },
            notchedOutline: {
                borderColor: "rgba(0, 0, 0, 0.23)",
                borderRadius: "8px",
                ".Mui-disabled ~ &": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                },
            },
            input: {
                padding: "14.5px 12px",
            },
            inputMarginDense: {
                paddingTop: "14.5px",
                paddingBottom: "14.5px",
            },
        },
        MuiInputLabel: {
            outlined: {
                color: "rgba(0, 0, 0, 0.54)",
                "&.MuiInputLabel-marginDense": {
                    transform: "translate(14px, 16px) scale(1)",
                },
                "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                },
            },
        },
        MuiSelect: {
            root: {
                "& ~ .MuiOutlinedInput-notchedOutline": {
                    top: 0,
                },
            },
            select: {
                lineHeight: 1.1875,
            },
            outlined: {
                borderRadius: "8px",
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                marginBottom: "12px",
                "& .MuiTypography-body1": {
                    fontSize: "1.125rem",
                    fontWeight: 500,
                },
            },
            dayLabel: {},
        },
        MuiPickersCalendar: {
            transitionContainer: {
                marginTop: "16px",
            },
        },
        MuiPickersDay: {
            day: {
                "& .MuiTypography-body2": {
                    fontSize: "1rem",
                },
                "& .MuiTouchRipple-root": {
                    display: "none",
                },
            },
            daySelected: {
                backgroundColor: "#D9D9D9",
                "&:hover": {
                    backgroundColor: "#D9D9D9",
                },
            },
            dayDisabled: {
                color: alpha("#343434", 0.2),
            },
            current: {
                color: "#52BFD3",
            },
        },
    },
});
export default theme;
