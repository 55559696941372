var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import AuthService from "./services/AuthService";
import { AuthContextProvider } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastContext";
import theme from "./theme";
import RenderRoutes from "./RenderRoutes";
import UserService from "./services/UserService";
import { Loading } from "./components/common/Loading";
export const Main = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    useEffect(() => {
        (function () {
            return __awaiter(this, void 0, void 0, function* () {
                const token = AuthService.getToken();
                if (token) {
                    try {
                        const user = yield UserService.getMe();
                        setUser(user);
                    }
                    catch (error) {
                        AuthService.logout();
                    }
                    setLoading(false);
                    return;
                }
                setLoading(false);
            });
        })();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(CssBaseline, null),
            loading ? (React.createElement(Loading, null)) : (React.createElement(AuthContextProvider, { user: user },
                React.createElement(ToastProvider, null,
                    React.createElement(RenderRoutes, null)))))));
};
