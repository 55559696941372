import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    title: {
        marginTop: "30px",
    },
}));
export const Loading = () => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.root },
        React.createElement(CircularProgress, null),
        React.createElement(Typography, { variant: "h4", className: classes.title }, "Loading...")));
};
