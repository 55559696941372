import axios from "axios";
class ApiClient {
    constructor() {
        // illegal due to private constructor
        // const apiClient = new ApiClient();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = axios.create({
                headers: {
                    common: {
                        isWebApp: true,
                    },
                },
            });
        }
        return this.instance;
    }
}
export default ApiClient.getInstance();
