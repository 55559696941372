import { Roles } from "../types/user";
import apiClient from "../api/ApiClient";
import { authHeader } from "../utils/authUtils";
import { apiConfig } from "../config/apiConfig";
const API_URL = apiConfig.apiUrl;
class UserService {
    getMe() {
        return apiClient
            .get(`${API_URL}/users/self/get-info`, { headers: authHeader() })
            .then((response) => {
            if (!response.data.roles.includes(Roles.admin)) {
                throw new Error("Incorrect user permissions");
            }
            return response.data;
        });
    }
}
export default new UserService();
