import * as React from "react";
import { useEffect, useState } from "react";
import { allowedPrivateRoutes } from "../config/rolesRoutes";
const AuthContext = React.createContext({
    authUser: null,
    allowedRoutes: [],
});
const AuthContextProvider = ({ children, user }) => {
    const [authUser, setAuthUser] = useState(user);
    useEffect(() => {
        if (!authUser || user.id !== authUser.id) {
            setAuthUser(user);
        }
        return () => {
            setAuthUser(null);
        };
    }, [user]);
    const context = {
        authUser,
        setAuthUser,
        allowedRoutes: allowedPrivateRoutes,
    };
    return React.createElement(AuthContext.Provider, { value: context }, children);
};
export { AuthContextProvider, AuthContext };
