import React from "react";
import DomainRoundedIcon from "@material-ui/icons/DomainRounded";
import AssignmentIndRoundedIcon from "@material-ui/icons/AssignmentIndRounded";
import SubscriptionsRoundedIcon from "@material-ui/icons/SubscriptionsRounded";
import SettingsApplicationsRoundedIcon from "@material-ui/icons/SettingsApplicationsRounded";
import Routes from "../constants/routes";
const CoachesList = React.lazy(() => import(/* webpackChunkName: "admin-CoachesList" */ "../components/admin/coaches/CoachesList"));
const Settings = React.lazy(() => import(/* webpackChunkName: "admin-Settings" */ "../components/admin/Settings"));
const SeminarsList = React.lazy(() => import(
/* webpackChunkName: "admin-SeminarsList" */ "../components/admin/seminars/SeminarsList"));
const Coach = React.lazy(() => import(/* webpackChunkName: "admin-Coach" */ "../components/admin/coaches/Coach"));
const CustomersList = React.lazy(() => import(
/* webpackChunkName: "admin-CustomersList" */ "../components/admin/customers/CustomersList"));
const Customer = React.lazy(() => import(/* webpackChunkName: "admin-Customer" */ "../components/admin/customers/Customer"));
const Seminar = React.lazy(() => import(/* webpackChunkName: "admin-Seminar" */ "../components/admin/seminars/Seminar"));
const SeminarVideo = React.lazy(() => import(
/* webpackChunkName: "admin-SeminarVideo" */ "../components/admin/seminars/SeminarVideo/"));
const privateComponents = {
    coaches: {
        component: CoachesList,
        url: Routes.COACHES,
        title: "Coaches",
        icon: AssignmentIndRoundedIcon,
        isMenuItem: true,
    },
    coach: {
        component: Coach,
        url: Routes.COACH,
        title: "Coach",
        icon: AssignmentIndRoundedIcon,
        isMenuItem: false,
    },
    settings: {
        component: Settings,
        url: Routes.SETTINGS,
        title: "Settings",
        icon: SettingsApplicationsRoundedIcon,
        isMenuItem: false,
    },
    seminars: {
        component: SeminarsList,
        url: Routes.SEMINARS,
        title: "Seminars",
        icon: SubscriptionsRoundedIcon,
        isMenuItem: true,
    },
    seminar: {
        component: Seminar,
        url: Routes.SEMINAR,
        title: "Seminar",
        icon: SubscriptionsRoundedIcon,
        isMenuItem: false,
    },
    seminarVideo: {
        component: SeminarVideo,
        url: Routes.SEMINAR_VIDEO,
        title: "Seminar",
        icon: SubscriptionsRoundedIcon,
        isMenuItem: false,
    },
    customers: {
        component: CustomersList,
        url: Routes.CUSTOMERS,
        title: "Customers",
        icon: DomainRoundedIcon,
        isMenuItem: true,
    },
    customer: {
        component: Customer,
        url: Routes.CUSTOMER,
        title: "Customer",
        icon: DomainRoundedIcon,
        isMenuItem: false,
    },
};
export const allowedPrivateRoutes = [
    privateComponents.customers,
    privateComponents.coaches,
    privateComponents.seminars,
    privateComponents.settings,
    privateComponents.coach,
    privateComponents.customer,
    privateComponents.seminar,
    privateComponents.seminarVideo,
];
