import { Main } from "./Main";
import ReactDOM from "react-dom";
import React from "react";
export class App {
    constructor() {
        this.render();
    }
    render() {
        ReactDOM.render(React.createElement(Main, { app: this }), document.querySelector("#app") || document.createElement("div"));
    }
}
new App();
if (module.hot) {
    module.hot.accept("./Main", () => {
        const NewApp = require("./Main").Main;
        ReactDOM.render(React.createElement(NewApp), document.querySelector("#app") || document.createElement("div"));
    });
}
