import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import { AuthContext } from "../../context/AuthContext";
import AuthService from "../../services/AuthService";
import Routes from "../../constants/routes";
export const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#F8F8F8",
        color: theme.palette.primary.main,
        boxShadow: "none",
        height: "66px",
        padding: "0 16px",
        [theme.breakpoints.up("lg")]: {
            padding: 0,
        },
    },
    logoButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    logo: {
        height: "44px",
        width: "44px",
        marginRight: theme.spacing(1),
    },
    toolbar: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "1024px",
        margin: "0 auto",
        width: "100%",
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
    },
    menuContainer: {
        display: "flex",
        alignItems: "center",
    },
    menuLink: {
        color: "rgba(52, 52, 52, 0.5)",
        fontSize: "1.125rem",
        fontWeight: 500,
        letterSpacing: "0.078125rem",
        textDecoration: "none",
        padding: "5px 8px 5px 8px",
        marginLeft: "8px",
        display: "flex",
        alignItems: "center",
    },
    active: {
        color: "#343434",
    },
    whiteText: {
        color: theme.palette.common.white,
    },
    icon: {
        marginRight: "3px",
    },
    avatar: {
        borderRadius: "50%",
        height: "32px",
        width: "32px",
        background: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "14px",
    },
    avatarIcon: {
        color: theme.palette.common.white,
    },
    link: {
        textDecoration: "none",
        color: theme.palette.common.black,
    },
}));
const Header = () => {
    var _a, _b, _c, _d;
    const classes = useStyles();
    const { allowedRoutes, authUser, setAuthUser } = useContext(AuthContext);
    const mainRoute = (_b = (_a = allowedRoutes[0]) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : "/";
    const { pathname } = useLocation();
    const menuItems = allowedRoutes.filter((route) => route.isMenuItem);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleLogout = () => {
        AuthService.logout();
        setAuthUser(null);
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleListKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    };
    const roles = (_c = authUser === null || authUser === void 0 ? void 0 : authUser.roles) !== null && _c !== void 0 ? _c : [];
    const mainRole = (_d = roles[0]) !== null && _d !== void 0 ? _d : "";
    if (!authUser)
        return null;
    return (React.createElement(AppBar, { className: classes.appBar, position: "relative" },
        React.createElement(Toolbar, { className: classes.toolbar, disableGutters: true },
            React.createElement("div", { className: classes.logoContainer },
                React.createElement(IconButton, { edge: "start", size: "small", color: "inherit", disableRipple: true, component: Link, to: mainRoute, className: classes.logoButton },
                    React.createElement("img", { src: "/assets/images/logo.png", alt: "Low Insulin Methode", className: classes.logo })),
                React.createElement(Typography, { variant: "h6" }, mainRole)),
            React.createElement("div", { className: classes.menuContainer },
                menuItems.map((route, index) => (React.createElement(Typography, { variant: "subtitle1", key: `main-menu-item-${index}` },
                    React.createElement(Link, { className: classNames(classes.menuLink, {
                            [classes.active]: pathname.includes(route.url),
                        }), to: route.url }, route.title)))),
                authUser && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classes.avatar },
                        React.createElement(IconButton, { ref: anchorRef, color: "inherit", onClick: handleToggle },
                            React.createElement(PersonRoundedIcon, { fontSize: "small", className: classes.avatarIcon }))),
                    React.createElement(Popper, { open: open, anchorEl: anchorRef.current, role: undefined, transition: true, disablePortal: true }, ({ TransitionProps, placement }) => (React.createElement(Grow, Object.assign({}, TransitionProps, { style: {
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        } }),
                        React.createElement(Paper, null,
                            React.createElement(ClickAwayListener, { onClickAway: handleClose },
                                React.createElement(MenuList, { autoFocusItem: open, id: "menu-list-grow", onKeyDown: handleListKeyDown },
                                    React.createElement(MenuItem, { onClick: handleClose },
                                        React.createElement(Link, { className: classes.link, to: Routes.SETTINGS }, "Settings")),
                                    React.createElement(MenuItem, { onClick: handleLogout }, "Logout")))))))))))));
};
export default Header;
